import type { TaxCountryCode, TaxCustom } from '~/types/tax.types'

const shouldAddUserCountryTax = ref<boolean>(false)

export async function useUserCountryTax() {
  // Loading the saved value from cookie (and updating it when necessary)
  useCookieSaving<boolean>('shouldAddUserCountryTax', shouldAddUserCountryTax)

  // Get user tax data
  const userInfos = await useUserInfos()
  const userTaxCountryCode = computed<TaxCountryCode | null>(() => userInfos.data.value?.tax_country_code as TaxCountryCode | null ?? null)
  const userTaxCustom = computed<TaxCustom | null>(() => userInfos.data.value?.tax_custom as TaxCustom | null ?? null)

  return {
    shouldAddUserCountryTax,
    userTaxCountryCode,
    userTaxCustom,
  }
}